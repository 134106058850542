<template>
	<!--서비스 상세 페이지 - 암호-->
	<div class="Cycontainer" v-if="computedValue === `ko`">
		<!--content1-->
		<!-- service crypto main benner-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<!--header text-->
					<h2>업무를 간편하게 도와주는</h2>
					<h1>BI Mining</h1>
					<p>
						BI Mining은 비즈니스 진행에 도움을 주는 분석 데이터 시각화
						도구입니다.<br />
						처음 접하는 사용자도 손쉽게 활용 가능한 업무 보조 툴로서, 자동 차트
						제작 기능을<br />
						사용하여 즉각적으로 데이터를 시각화해주는 서비스입니다.<br />
						하나의 툴 안에서 엑셀 수정과 보고서 작성을 한 번에 할 수 있고
						작성한<br />
						차트가 포함된 템플릿을 활용하여 업무의 효율을 높여줍니다.
					</p>
					<!--header button-->
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">지금 문의하기</button>
						</router-link>
						<a href="http://bi-mining.org/">
							<button class="btn--md btn--fill-gray">접속하기</button>
						</a>
					</div>
				</div>
				<!-- service crypto main benner end-->
				<!--header img-->
				<div class="serviceBIcontent__mainimg">
					<img :src="MiningTitle" alt="Mining Title" />
				</div>
			</div>
			<!-- content1 end -->

			<!-- content2 -->
			<div id="content2">
				<div class="servicecontent__wrapper--imgbg">
					<img :src="MiningServeBg" />
					<div class="servicecontent__textbox">
						<h1>비즈니스 진행에 도움을 주는</h1>
						<div class="servicecontent_stroke"></div>
						<h2>BI Mining</h2>
						<p>처음 접하는 사용자도 손쉽게 활용 가능한 업무 보조 툴</p>
					</div>
				</div>
				<div class="servicecontent2_box">
					<div class="servicecontent2_textbox">
						<h1>높은 활용도를 가진 BI Mining</h1>
						<div class="border-graient"></div>
						<p>
							BI Mining은 데이터 입력, 보고서 작성, Powerpoint 템플릿까지<br />
							업무 전반에 걸쳐 필요한 업무 보조 툴을 한 곳에서 <br />
							무료로 활용할 수 있는 서비스입니다.
						</p>
					</div>
				</div>
			</div>
			<!--content2 end-->

			<!--content3-->
			<div id="content3">
				<div class="content3-title_bg"></div>
				<div class="content3-title">
					<h1>다양한 환경에서 쉽게 활용</h1>
					<p>
						데이터 시각화를 통해 업무를<br />
						효율적으로 처리할 수 있는 웹 기반 플랫폼
					</p>
				</div>
				<div class="content3_box">
					<img :src="MiningContent3" />
				</div>
			</div>

			<!--content4-->
			<div id="content4">
				<div class="content4-title">
					<p>BI Mining Detail Service</p>
					<h2>BI Mining 상세 서비스</h2>
				</div>
				<div class="content4_box">
					<div class="content_box">
						<div class="img_box1"><img :src="BITempImg" /></div>
						<div class="text_box">
							<h2>PPT 템플릿</h2>
							<h3>다양한 템플릿을 활용해서 손쉽게 제작</h3>
							<div class="border"></div>
							<p>
								다양한 디자인의 PPT 템플릿을 활용하여 빠르고 간편하게 PPT를
								<br />
								제작할 수 있습니다. 또한 미리 만들어둔 데이터 차트를 템플릿에<br />보안
								강도를 제공할 수 있고 전자서명, 키 관리 등에 손쉽게 삽입할 수
								있습니다.
							</p>
						</div>
					</div>

					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>차트 작성</h2>
							<h3>선택한 데이터를 여러 종류의 차트로 자동 생성</h3>
							<div class="border"></div>
							<p>
								데이터를 일일이 입력하거나 수정할 필요 없이, 하나의 데이터
								차트만 <br />
								삽입하여 다양한 종류의 차트로 자동 생성할 수 있습니다. 또한
								생성한 <br />
								차트를 한 대시보드에서 확인할 수 있어 데이터 간 비교가
								용이합니다.
							</p>
						</div>
						<div class="img_box2"><img :src="BIChartImg" /></div>
					</div>

					<div class="content_box">
						<div class="img_box1"><img :src="BIExcelImg" /></div>

						<div class="text_box">
							<h2>엑셀 작성</h2>
							<h3>Mining에서 간편하게 수치를 입력하고 저장</h3>
							<div class="border"></div>
							<p>
								번거롭게 여러 프로그램을 켜 둘 필요 없이, Mining에서 데이터
								<br />
								수치 입력까지 한 번에 가능합니다. 엑셀 파일은 이후 데이터 <br />
								시각화에도 편하게 연동하여 활용할 수 있으며, 엑셀 파일로 <br />
								내보낼 수 있습니다.
							</p>
						</div>
					</div>
					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>보고서 작성</h2>
							<h3>만들어진 데이터를 한 곳에서 정리하고 작성</h3>
							<div class="border"></div>
							<p>
								엑셀 작성, 차트 작성을 통해 만든 데이터 시각화 차트를 활용하여
								<br />
								보고서를 편리하게 제작할 수 있습니다. 여러 프로그램을
								번거롭게<br />
								켜둘 필요 없이 BI Mining에서 보고서 작성을 끝낼 수 있습니다.
							</p>
						</div>
						<div class="img_box2"><img :src="BIReportImg" /></div>
					</div>
				</div>
			</div>
			<!--content4 end-->
			<div id="content5Cy">
				<!--content5 title-->
				<div class="content5cy-title">
					<h2>BI Mining 활용 사례</h2>
				</div>
				<!--content5_box-->
				<div class="content5_box">
					<!--box1-->
					<div class="box1">
						<div class="box_title">
							<h1>01</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>일반 기업</h2>
							<p>
								데이터 시각화가 필요한<br />
								일반 업무 기업 및 기관에서 활용
							</p>
						</div>
					</div>
					<!--box1 end-->
					<!--box2-->
					<div class="box2">
						<div class="box_title">
							<h1>02</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>데이터 분석기관</h2>
							<p>수시로 변화하는 데이터를<br />간편하게 시각화</p>
						</div>
					</div>
					<!--box2 end-->
					<!--box3-->
					<div class="box3">
						<div class="box_title">
							<h1>03</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>세무업계</h2>
							<p>종합 정보를 효율적으로<br />나눠야 할 때 활용</p>
						</div>
					</div>
				</div>
				<!--box3 end-->
				<!--content5_box end-->
			</div>
			<!--content5 end-->
			<!--컨텐츠 6-->
			<div id="BIcontent6">
				<div class="BIBorder servicecontent__wrapper">
					<div class="blueBorder">
						<div class="serviceBI__textbox">
							<div class="H1Name">
								데이터를 활용하는 업무를 가진 모두에게 추천하는 서비스
							</div>
							<div class="H3Namee">
								데이터를 비교하고 관리하는 분들 모두에게 추천하는
								서비스입니다.<br />
								지금 바로 BI Mining을 활용하여 효율적인 업무 진행을 경험해
								보세요.
							</div>

							<div class="GOBI">
								<a href="http://bi-mining.org/">
									<button class="GoToBI">BI Mining 바로가기</button>
								</a>
								<router-link to="/inquiry">
									<button class="GoToAsk">문의하기</button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="Cycontainer" v-else-if="computedValue === `en`">
		<!--content1-->
		<!-- service crypto main benner-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<!--header text-->
					<h2>Helping simplify tasks</h2>
					<h1>BI Mining</h1>
					<p>
						BI Mining is a data visualization tool that aids in business
						operations.<br />
						It is an easy-to-use assistant tool, even for first-time users,
						offering<br />
						automatic chart creation to instantly visualize data.<br />
						Within a single tool, you can edit Excel files and create reports<br />
						simultaneously, and by utilizing the provided templates, you can<br />
						easily create PowerPoint presentations, enhancing work efficiency.
					</p>
					<!--header button-->
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">Contact Us</button>
						</router-link>
						<a href="http://bi-mining.org/">
							<button class="btn--md btn--fill-gray">Access</button>
						</a>
					</div>
				</div>
				<!-- service crypto main benner end-->
				<!--header img-->
				<div class="serviceBIcontent__mainimg">
					<img :src="MiningTitle" />
				</div>
			</div>
			<!-- content1 end -->

			<!-- content2 -->
			<div id="content2">
				<div class="servicecontent__wrapper--imgbg">
					<img :src="MiningServeBg" />
					<div class="servicecontent__textbox">
						<h1>Helping Your Business Progress</h1>
						<div class="servicecontent_stroke"></div>
						<h2>BI Mining</h2>
						<p>
							A work assistance tool that even first-time users can easily use
						</p>
					</div>
				</div>
				<div class="servicecontent2_box">
					<div class="servicecontent2_textbox">
						<h1>BI Mining with High Usability</h1>
						<div class="border-graient"></div>
						<p>
							BI Mining is a service that allows you to utilize a
							comprehensive<br />
							range of business assistance tools in one place, covering<br />
							everything from data entry and report creation to<br />
							PowerPoint template design.
						</p>
					</div>
				</div>
			</div>
			<!--content2 end-->

			<!--content3-->
			<div id="content3">
				<div class="content3-title_bg"></div>
				<div class="content3-title">
					<h1>Easily utilized in various environments!</h1>
					<p>
						A data visualization service that allows you to easily<br />
						experience task automation in any environment<br />
						without the need for program downloads.
					</p>
				</div>
				<div class="content3_box">
					<img :src="MiningContent3Eng" />
				</div>
			</div>

			<!--content4-->
			<div id="content4">
				<div class="content4-title">
					<p>BI Mining Detail Service</p>
					<h2>BI Mining Detail Service</h2>
				</div>
				<div class="content4_box">
					<div class="content_box">
						<div class="img_box1"><img :src="BITempImg" /></div>
						<div class="text_box">
							<h2>PPT Templates</h2>
							<h3>
								Easily create presentations using a<br />variety of templates.
							</h3>
							<div class="border"></div>
							<p>
								You can quickly and easily create PPTs using a variety
								<br />
								of designs in PPT templates. Additionally, you can<br />easily
								insert pre-made data charts into the templates.
							</p>
						</div>
					</div>

					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>Create Charts</h2>
							<h3>
								Automatically generate various<br />types of charts from the
								selected data.
							</h3>
							<div class="border"></div>
							<p>
								You can automatically generate various types of charts by<br />
								inserting just one data chart, without the need to manually<br />
								input or modify the data. Additionally, you can view the<br />
								generated charts on a single dashboard, making it easy to<br />
								compare the data.
							</p>
						</div>
						<div class="img_box2"><img :src="BIChartImg" /></div>
					</div>

					<div class="content_box">
						<div class="img_box1"><img :src="BIExcelImg" /></div>

						<div class="text_box">
							<h2>Create Excel</h2>
							<h3>Easily input and save values in Mining</h3>
							<div class="border"></div>
							<p>
								You can input data values all at once in Mining without
								<br />
								the hassle of opening multiple programs. The Excel files<br />
								can be easily linked for data visualization later and can<br />
								also be exported as Excel files.
							</p>
						</div>
					</div>
					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>Create Report</h2>
							<h3>Organize and create the generated<br />data in one place</h3>
							<div class="border"></div>
							<p>
								You can conveniently create reports using data visualization
								<br />
								charts generated through Excel and chart creation. There’s no
								<br />
								need to keep multiple programs open, as you can complete<br />report
								writing in BI Mining.
							</p>
						</div>
						<div class="img_box2"><img :src="BIReportImg" /></div>
					</div>
				</div>
			</div>
			<!--content4 end-->
			<div id="content5Cy">
				<!--content5 title-->
				<div class="content5cy-title">
					<h2>Use Cases for BI Mining</h2>
				</div>
				<!--content5_box-->
				<div class="content5_box">
					<!--box1-->
					<div class="box1">
						<div class="box_title">
							<h1>01</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>General Businesses</h2>
							<p>
								Used by general business enterprises and<br />
								institutions that need data visualization.
							</p>
						</div>
					</div>
					<!--box1 end-->
					<!--box2-->
					<div class="box2">
						<div class="box_title">
							<h1>02</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>Data Analysis Agency</h2>
							<p>Easily visualize frequently<br />changing data.</p>
						</div>
					</div>
					<!--box2 end-->
					<!--box3-->
					<div class="box3">
						<div class="box_title">
							<h1>03</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>Tax Industry</h2>
							<p>
								Utilized when it is<br />necessary to efficiently<br />share
								comprehensive<br />information.
							</p>
						</div>
					</div>
				</div>
				<!--box3 end-->
				<!--content5_box end-->
			</div>
			<!--content5 end-->
			<!--컨텐츠 6-->
			<div id="BIcontent6">
				<div class="BIBorder servicecontent__wrapper">
					<div class="blueBorder">
						<div class="serviceBI__textbox">
							<div class="H1Name">
								A service recommended for everyone<br />involved in data-driven
								tasks
							</div>
							<div class="H3Namee">
								This is a service recommended for everyone involved in comparing
								and managing data.<br />
								Experience efficient work processes now by utilizing BI Mining.
							</div>

							<div class="GOBI">
								<a href="http://bi-mining.org/">
									<button class="GoToBI">Go to BI Mining</button>
								</a>
								<router-link to="/inquiry">
									<button class="GoToAsk">Contact Us</button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--container end-->
</template>

<script>
import MiningTitle from '../assets/images/service/BI-MiningTitle.png';
import MiningServeBg from '../assets/images/service/MiningServeBg.png';
import MiningContent3 from '../assets/images/service/MiningContent3.svg';
import MiningContent3Eng from '../assets/images/service/MiningContent3Eng.svg';
import BITempImg from '../assets/images/service/BITempImg.svg';
import BIChartImg from '../assets/images/service/BIChartImg.svg';
import BIExcelImg from '../assets/images/service/BIExcelImg.svg';
import BIReportImg from '../assets/images/service/BIReportImg.svg';

export default {
	data() {
		return {
			service: 1,
			BIExcelImg,
			BIReportImg,
			MiningContent3,
			BITempImg,
			BIChartImg,
			MiningServeBg,
			MiningContent3Eng,
			MiningTitle,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
